<template>
    <!-- eslint-disable -->
    <Form :action="action" />
</template>

<script>
import Form from './Form.vue'

export default {
    data(){
        return{
            action: 'Tambah',
        }
    },
    components: {
        Form
    }
}
</script>